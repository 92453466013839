// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  main: 'Principal',
  to_advertisers: 'Asesores',
  to_publishers: 'Publicistas',
  promote_goods: 'Promover bienes',
  promote_products: 'Promover productos y servicios.\n' +
    'Sólo el tráfico objetivo!',
  easy_integration: 'Proporcionar: Inicio fácil, conexión rápida, interfaz conveniente y cuenta personal, ventas administradas\n',
  join: 'Unirse',
  trusted_by_advertisers: 'Los anunciantes de todo el mundo confían en nosotros',
  every_advertiser_can: 'Cada socio en nuestra red tiene la capacidad de rastrear todos los detalles del pedido y el gerente personal que está listo para asesorarle en cualquier momento.\n',
  start_selling: 'Empezar a vender',
  what_are_benefits: 'Cuáles son los beneficios?',
  minimal_risks: 'Riesgos mínimos',
  comfortable_office: 'Cómoda oficina',
  personal_manager: 'Gerente personal',
  target_traffic: 'Tráfico objetivo',
  payment_only_actual: 'Pago solo por ventas reales',
  dashboard_online_statistics: 'Deshboard, estadísticas en línea, CRM con pedidos y otras comodidades',
  all_matters: 'Todos los asuntos se tratan a través del funcionario responsable personal',
  traffic_and_conversion: 'Tráfico y conversión bajo control constante de nuestros marketers',
  scheme_of_operation: 'Esquema de funcionamiento',
  advertiser_registration: 'Registro de anunciante',
  manager_familiarization: 'Familiarización del gerente',
  billing: 'Facturación',
  design_making: 'Diseño',
  sourcing: 'Abastecimiento',
  we_calling: 'Estamos llamando a la tapa',
  webmaster_registration: 'Registro de webmaster',
  two_click_register: 'Registro solo 2 clics',
  password: 'Contraseña',
  email: 'Correo electrónico',
  enter_email: 'Introduzca su correo electrónico',
  email_not_valid: 'No es un correo electrónico válido ',
  enter_password: 'Introduzca su contraseña',
  enter_password_with_rules: 'Al menos 6 caracteres, incluyendo números, comodines, letras latinas mayúsculas y minúsculas.',
  sign_up: 'Registro',

  affiliate_network: 'RED DE AFILIADOS CPA',
  start_earning: 'Empieza a ganar más ahora',
  monetize_traffic: 'Monetizar el tráfico',
  about_utgid_team: 'Acerca del equipo de UTGid',
  our_goals: 'Nuestro objetivo es un excelente servicio, para todos los socios, desde cualquier parte del mundo.\n' +
    '  - ¿Cómo se puede medir esto? - usted pregunta\n' +
    'Te responderemos. Este es el trabajo minucioso de nuestros gerentes y operadores que trabajan arduamente para usted todos los días.\n' +
    'Búsqueda constante de ideas creativas para mejorar la conversión de cada oferta.\n' +
    'Implementación regular de sus deseos de mejorar el servicio.\n' +
    '¡Esperamos una relación mutuamente beneficiosa!',
  our_benefits: 'Nuestros beneficios',
  regular_earnings: 'Habitual ganancias',
  expanded_stats: 'Ampliado estadísticas',
  verified_sources: 'Verificado fuentes',
  exclusive_offers: 'Ofertas exclusivas',
  we_offer: 'Ofrecemos',
  exclusive_offers_with: 'Ofertas exclusivas con una retención mínima',
  demanded_goods: 'Mercancías demandadas en todas las categorías',
  regular_updating: 'Actualización periódica de las páginas de destino y seguimiento de la conversión',
  custom_call_center: 'Centro de llamadas personalizado con KPI disponible',
  professional_team: 'Equipo profesional de gerentes y marketers',
  convenient_admin: 'Administración conveniente con análisis "en tiempo real"',
  why_we: 'por qué nosotros',
  country_outreach: 'Extensión a los países',
  unique_scripts: 'Único guiones',
  system_monitoring: 'Monitorización continua del sistema',
  fault_tolerance: 'Tolerancia a fallos',
  professional_utgid_team: 'Equipo profesional de UTGid',
  publisher_already_work: 'Los editores ya trabajan con nosotros',
  selling_landing_page: 'Venta de Landing Page',
  register_as_advertiser: 'Registrarse como anunciante',
  register_as_webmaster: 'Registrarse como webmaster',
  our_partners: 'Nuestros socios',
  rise_your_standarts: 'Elevar sus estándares',
  ready_to_expand_geo: 'Siempre estamos listos para expandir GEO!',
  all_rights_reserved: 'Todos los derechos reservados © 2022',

  transparent_schemes: 'Sistemas transparentes de monetización',
  we_have_large: 'Tenemos una gran selección de programas de socios, usted será capaz de elegir el más eficaz para monetizar su tráfico. Entre las muchas ofertas hay productos de alto rendimiento y ofertas completamente nuevas.\n',
  utgid_for_webmasters: 'UTGid para webmasters',
  with_full_offline: 'Con acceso completo sin conexión y soporte durante todo el día,  usted será capaz de ganar con UTGid desde el primer mes de cooperación.\n',
  get_down_to_work: 'Ponerse a trabajar',
  best_deal: 'Mejor oferta',
  quick_pay: 'Pago rápido',
  withdraw_money: 'Retire el dinero ganado en carteras electrónicas y tarjetas de crédito sin demora.',
  detailed_statistics: 'Estadísticas detalladas',
  real_time_statistics: 'Estadísticas en modo «tiempo real» para cada parámetro, clic y acción.',
  page_rotor: 'Rotor de página',
  superior_av_tests: 'Pruebas AV superiores Seleccione los banners y acabados de aterrizaje más efectivos',
  personal_technical_support: 'Asistencia técnica personal',
  high_quality_manager: 'Un gerente altamente calificado le asesorará en línea.',
  technologies_to_increase_income: 'Tecnologías para aumentar los ingresos',
  we_offer_advanced: 'Ofrecemos herramientas avanzadas de monetización, estadísticas detalladas y análisis de las fuentes de tráfico.',
  report_on_each_click: 'Informe sobre cada clic con todos los detalles básicos',
  we_show_the_webmaster: 'Mostramos al webmaster un informe sobre cada clic y acción. Todos los socios pueden rastrear y controlar sus ingresos de la manera más rápida posible.\n',
  ability_to_analyse: 'Capacidad de analizar la conversión por períodos',
  detailed_analytics: 'Análisis detallados demuestra momentos clave de la rentabilidad del tráfico, indica puntos positivos y negativos en la cuestión de la monetización.\n',
  content_rotation: 'Rotación automática de contenidos',
  webmasters_and_our:'Webmasters y nuestros gerentes pueden agregar cualquier número de banners y sitios, y nuestra tecnología elige la mayor conversión.  Todo para obtener el máximo beneficio de su tráfico.\n',
  convenient_ways: 'Maneras convenientes de retirar ganancias',
  withdraw_regularly: 'Retire regularmente sus ganancias de una manera conveniente para usted.',
  current_language_i18n: 'Es',
  registration_successful: 'El registro fue exitoso',
  have_an_account: '¿Tienes una cuenta?',
  log_in: 'Entrar en el sistema',
  unknown_role: 'Papel desconocido',
};

