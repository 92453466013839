// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  main: 'Main',
  to_advertisers: 'Advertisers',
  to_publishers: 'Publishers',
  promote_goods: 'Promote goods',
  promote_products: 'Promote products and services. Only the target traffic!',
  easy_integration: 'Provide: Easy start, Fast Connect Off, Convenient Interface & Personal Account, Managed Sales',
  join: 'Join',
  trusted_by_advertisers: 'We are trusted by advertisers around the world',
  every_advertiser_can: 'Each partner in our network has the ability to track all the details of the order and personal manager who is ready to advise you at any time.\n',
  start_selling: 'Start selling',
  what_are_benefits: 'What are the benefits?',
  minimal_risks: 'Minimal risks',
  comfortable_office: 'Comfortable office',
  personal_manager: 'Personal manager',
  target_traffic: 'Target traffic',
  payment_only_actual: 'Payment only for actual sales',
  dashboard_online_statistics: 'Dashboard, online statistics, CRM with orders and other conveniences',
  all_matters: 'All matters are handled through the personal responsible officer',
  traffic_and_conversion: 'Traffic and conversion under constant control of our marketers',
  scheme_of_operation: 'Scheme of operation',
  advertiser_registration: 'Advertiser registration',
  manager_familiarization: 'Manager familiarization',
  billing: 'Billing',
  design_making: 'Design making',
  sourcing: 'Sourcing',
  we_calling: 'We’re calling the lid',
  webmaster_registration: 'Webmaster registration',
  two_click_register: 'Register just 2 clicks',
  password: 'Password',
  email: 'E-mail',
  enter_email: 'Enter your email address',
  email_not_valid: 'Not a valid e-mail',
  enter_password: 'Enter your password',
  enter_password_with_rules: 'At least 6 characters, including numbers, wildcards, uppercase and lowercase Latin letters.',
  sign_up: 'Sign up',

  affiliate_network: 'AFFILIATE NETWORK',
  start_earning: 'Start earning more now',
  monetize_traffic: 'Monetize traffic',
  about_utgid_team: 'About UTGid Team',
  our_goals: 'Our goal is excellent service, for all partners, from anywhere in the world.\n' +
    '  - How can it be measured? - you ask\n' +
    'We will answer you. This is the painstaking work of our managers and operators who work hard for you every day.\n' +
    'Constant search for creative ideas to improve the conversion of each offer.\n' +
    'Regular implementation of your wishes to improve the service.\n' +
    'We look forward to a mutually beneficial relationship!',
  our_benefits: 'Our Benefits',
  regular_earnings: 'Regular earnings',
  expanded_stats: 'Expanded stats',
  verified_sources: 'Verified sources',
  exclusive_offers: 'Exclusive offers',
  we_offer: 'We offer',
  exclusive_offers_with: 'Exclusive offers with minimum hold',
  demanded_goods: 'Demanded goods in all categories',
  regular_updating: 'Regular updating of landing pages and conversion monitoring',
  custom_call_center: 'Custom Call Center with KPI available',
  professional_team: 'Professional team of managers and marketers',
  convenient_admin: 'Convenient admin with "real time" analytics',
  why_we: 'Why us',
  country_outreach: 'Country outreach',
  unique_scripts: 'Unique scripts',
  system_monitoring: 'Continuous system monitoring',
  fault_tolerance: 'Fault tolerance',
  professional_utgid_team: 'Professional UTGid team',
  publisher_already_work: 'Publishers already work with us',
  selling_landing_page: 'Selling Landing Page',
  register_as_advertiser: 'Sign up as advertiser',
  register_as_webmaster: 'Sign up as webmaster',
  our_partners: 'Our partners',
  rise_your_standarts: 'Raise your standards',
  ready_to_expand_geo: 'We are always ready to expand GEO!',
  all_rights_reserved: 'All rights reserved © 2022',

  transparent_schemes: 'Transparent schemes for monetization',
  we_have_large: 'We have a large selection of partner programs, you will be able to choose the most effective to monetize your traffic. Among the many offers are high-yield goods and completely new offers.\n',
  utgid_for_webmasters: 'UTGid for webmasters',
  with_full_offline: 'With full offline access and round-the-clock support,  you will be able to earn with UTGid from the first month of cooperation.\n',
  get_down_to_work: 'Get down to work',
  best_deal: 'Best deal',
  quick_pay: 'Quick pay',
  withdraw_money: 'Withdraw money earned on e-wallets and credit cards without delay.',
  detailed_statistics: 'Detailed statistics',
  real_time_statistics: 'Statistics in «real time» mode for each parameter, click and action.',
  page_rotor: 'Page rotor',
  superior_av_tests: 'Superior AV Tests Select the Most Effective Banners and Landing Finishes',
  personal_technical_support: 'Personal technical support',
  high_quality_manager: 'A highly qualified manager will advise you online.',
  technologies_to_increase_income: 'Technologies to Increase Income',
  we_offer_advanced: 'We offer advanced monetization tools, detailed statistics and analytics on the sources of traffic.',
  report_on_each_click: 'Report on each click with all basic details',
  we_show_the_webmaster: 'We show the webmaster a report on each click and action. All partners are able to track and control their income in the fastest way possible.\n',
  ability_to_analyse: 'Ability to analyze conversion by periods',
  detailed_analytics: 'Detailed analytics demonstrates key moments of profitability from traffic, indicates positive and negative points in the issue of monetization.\n',
  content_rotation: 'Automatic content rotation',
  webmasters_and_our: 'Webmasters and our managers can add any number of banners and sites, and our technology chooses the most conversion.  All for maximum profit from your traffic.\n',
  convenient_ways: 'Convenient ways to withdraw earnings',
  withdraw_regularly: 'Withdraw regularly your earnings in a convenient way for you.',
  current_language_i18n: 'En',
  registration_successful: 'Registration was successful',
  have_an_account: 'Have an account?',
  log_in: 'Log In',
  unknown_role: 'Unknown Role',
















};
