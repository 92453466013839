import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/IndexPage.vue') }],
  },
  {
    path: '/advertiser',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/AdvertiserLanding.vue') }
    ],
  },
  {
    path: '/publisher',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/PublisherLanding.vue') },
    ],
  },
  {
    path: '/advertiser/register',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      { path: '', props: { type: 'advertiser'}, component: () => import('pages/RegisterPage.vue') }
    ],
  },
  {
    path: '/publisher/register',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      { path: '', props: { type: 'publisher'}, component: () => import('pages/RegisterPage.vue') }
    ],
  },
  {
    path: '/advertiser/login',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      { path: '', props: { type: 'advertiser'}, component: () => import('pages/LoginPage.vue') }
    ],
  },
  {
    path: '/publisher/login',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      { path: '', props: { type: 'publisher'}, component: () => import('pages/LoginPage.vue') }
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
